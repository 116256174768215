<template lang="html">
    <v-list>
        <template v-for="menuItem in menu">
            <v-list-item v-if="!menuItem.subMenu" :key="menuItem.url">
                <v-list-item-content>
                    <router-link :to="menuItem.url" @click="handleCloseDrawer">
                        {{ menuItem.text }}
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <v-list-group v-else no-action :key="menuItem.url">
                <template v-slot:activator>
                    <v-list-item-content>
                        <router-link :to="menuItem.url" @click="handleCloseDrawer">
                            {{ menuItem.text }}
                        </router-link>
                    </v-list-item-content>
                </template>
                <mobile-submenu :menu="menuItem.subMenu" />
            </v-list-group>
        </template>
    </v-list>
</template>

<script>
export default {
    name: 'MobileSubmenu',
    props: {
        menu: {
            type: Array,
            default: () => {}
        }
    },
    methods: {
        handleCloseDrawer() {
            this.$store.commit('app/toggleDrawer', false);
        }
    }
};
</script>

<style lang="scss" scoped></style>
