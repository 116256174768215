<template lang="html">
    <footer class="ps-footer--second dark">
        <div class="ps-footer__top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-12">
                        <widget-footer-about-site />
                    </div>
                    <div class="col-lg-3 col-sm-4 col-6">
                        <!--<widget-footer-categories />-->
                    </div>
                    <div class="col-lg-3 col-sm-4 col-6">
                    </div>
                    <div class="col-lg-3  col-sm-4 col-12">
                        <!--<widget-footer-address />-->
                        <widget-footer-quick-link />
                    </div>
                </div>
            </div>
        </div>
        <div class="ps-footer__bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="ps-footer__copyright">
                            <p>
                                &copy; 2020. Copyright
                                {{ host }}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ps-footer__social">
                            <social-links />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import WidgetFooterAboutSite from '@/components/shared/widgets/WidgetFooterAboutSite';
//import WidgetFooterCategories from '@/components/shared/widgets/WidgetFooterCategories';
import WidgetFooterQuickLink from '@/components/shared/widgets/WidgetFooterQuickLink';
//import WidgetFooterAddress from '@/components/shared/widgets/WidgetFooterAddress';
import SocialLinks from '@/components/elements/SocialLinks';
export default {
    name: 'FooterDefault',
    components: {
        SocialLinks,
//        WidgetFooterAddress,
        WidgetFooterQuickLink,
//        WidgetFooterCategories,
        WidgetFooterAboutSite
    },
    computed: {
        host () {
            return window.location.hostname;
        }
    }
};
</script>

<style lang="scss" scoped></style>
