<template lang="html">
    <aside class="ps-widget ps-widget--footer">
        <h4 class="ps-widget__heading">
            <span>Quick Links</span>
        </h4>
        <div class="ps-widget__content">
            <ul class="ps-list">
                <li>
                    <router-link to="/privacy">
                        Privacy
                    </router-link>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
export default {
    name: 'WidgetFooterQuickLink'
};
</script>

<style lang="scss" scoped></style>
