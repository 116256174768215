<template lang="html">
    <v-navigation-drawer v-model="drawer" width="100%" absolute temporary>
        <div class="ps-panel--menu-sidebar">
            <div class="ps-panel__header">
                <logo />
                <a class="ps-drawer__close" @click.prevent="handleCloseDrawer">
                    <i class="feather icon icon-x"></i>
                </a>
            </div>
            <div class="ps-panel__content">
                <v-list class="menu--mobile">
                    <template v-for="menuItem in menuItems">
                        <v-list-group v-if="menuItem.subMenu" no-action :key="menuItem.url">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <router-link :to="menuItem.url" @click="handleCloseDrawer">
                                        {{ menuItem.text }}
                                    </router-link>
                                </v-list-item-content>
                            </template>
                            <mobile-submenu :menu="menuItem.subMenu" />
                        </v-list-group>
                        <v-list-item v-else :key="menuItem.url">
                            <v-list-item-content>
                                <router-link :to="menuItem.url" @click="handleCloseDrawer">
                                    {{ menuItem.text }}
                                </router-link>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </div>
            <div class="ps-panel__footer"></div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { primaryMenu } from '@/static/data/menu.json';
import MobileSubmenu from '@/components/shared/mobile/modules/MobileSubmenu';
import Logo from '@/components/elements/Logo';
export default {
    name: 'DrawerMenu',
    components: { MobileSubmenu, Logo },
    computed: {
        ...mapState({
            isDrawer: state => state.app.isDrawer
        }),
        drawer: {
            get() {
                return this.isDrawer;
            },
            set(newValue) {
                this.$store.commit('app/toggleDrawer', newValue);
                return newValue;
            }
        },
        menuItems() {
            return primaryMenu;
        }
    },
    methods: {
        handleCloseDrawer() {
            this.$store.commit('app/toggleDrawer', false);
        }
    }
};
</script>
