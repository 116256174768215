<template lang="html">
    <div :class="`ps-search ${isSearch === true ? 'active' : ''}`" id="site-search">
        <a class="ps-btn--close" href="#" @click.prevent="handleCloseSearch"></a>
        <div class="ps-search__content">
            <form class="ps-form--primary-search" method="get" @submit.prevent="">
                <input
                    class="form-control"
                    name="keyword"
                    type="text"
                    placeholder="Search for..."
                />
                <button>
                    <i class="feather icon icon-search"></i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'FormSearch',
    computed: {
        ...mapState({
            isSearch: state => state.app.isSearch
        })
    },
    methods: {
        handleCloseSearch() {
            this.$store.commit('app/toggleSearch', false);
        }
    }
};
</script>

<style lang="scss" scoped></style>
