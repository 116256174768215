<template lang="html">
	<v-app>
		<v-main>
			<header-default classes="fixed header--transparent" />
			<header-mobile />
			<router-view></router-view>
			<footer-default />
			<form-search />
			<drawer-menu />
		</v-main>
	</v-app>
</template>

<script>
import HeaderDefault from '@/components/shared/headers/HeaderDefault';
import FooterDefault from '@/components/shared/footers/FooterDefault';
import FormSearch from '@/components/shared/forms/FormSearch';
import HeaderMobile from '@/components/shared/mobile/HeaderMobile';
import DrawerMenu from '@/components/shared/drawers/DrawerMenu';

export default {
    components: {
        DrawerMenu,
        HeaderMobile,
        FormSearch,
        FooterDefault,
        HeaderDefault
    }
};
</script>

<style lang="scss">
@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
</style>
