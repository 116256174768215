<template lang="html">
    <aside class="ps-widget ps-widget--about-site">
        <a :href="'mailto:support@help.' + host">support@help.{{host}}</a>
    </aside>
</template>

<script>
export default {
    name: 'WidgetFooterAboutSite',
    props: {
        dark: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        host () {
            return window.location.hostname;
        }
    }
};
</script>

<style lang="scss" scoped></style>
