<template lang="html">
    <ul :class="classes">
        <template v-for="item in menuItems">
            <MenuDropdown v-if="item.subMenu" :menu-data="item" :key="item.url" />
            <MenuMega v-else-if="item.mega" :menu-data="item" :key="item.url" />
            <li v-else :key="item.text">
                <router-link :to="item.url">
                    {{ item.text }}
                </router-link>
            </li>
        </template>
    </ul>
</template>

<script>
import MenuDropdown from './MenuDropdown';
import MenuMega from './MenuMega';
export default {
    name: 'MenuDefault',
    components: { MenuMega, MenuDropdown },
    props: {
        classes: {
            type: String,
            default: 'menu'
        },
        menuItems: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped></style>
